import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Select, Card, notification, ConfigProvider, Result, Alert, Checkbox, InputNumber, DatePicker } from 'antd';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import changeHeight from '../data/changeHeight';
import PhoneInput from "antd-phone-input";

const FormLeadExpat = () => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#FFFFFF');
    const [searchParams] = useSearchParams({});
    const [form] = Form.useForm();
    const refForm = useRef(0);
    const refResult = useRef(0);
    const [result, setResult] = useState("collapse");
    const [heightResult, setHeightResult] = useState(0);
    const [api, contextHolder] = notification.useNotification();
    const [cantidadHijos, setCantidadHijos] = useState(0);
    const [complementoPaternidadFile, setComplementoPaternidadFile] = useState(null);

    useEffect(() => {
        if (searchParams.get("primaryColor")) {
            setPrimaryColor('#' + searchParams.get("primaryColor"));
            setResult("none");
            setHeightResult(refResult.current.clientHeight);
        }
        if (searchParams.get("parentUrl")) {
            form.setFieldsValue({ url: searchParams.get("parentUrl") });
        }
    }, [searchParams]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === refForm.current) {
                    changeHeight(entry.target.clientHeight);
                    break;
                }
            }
        });

        resizeObserver.observe(refForm.current);

        return () => {
            resizeObserver.unobserve(refForm.current);
        };
    }, []);

    window.addEventListener('message', (event) => {
        if (event.data.type === "from-parent") {
            if (event.data.fbp === 'null' || event.data.fbp === null || event.data.fbp === undefined) {
                form.setFieldsValue({ fbp: " " });
            } else {
                form.setFieldsValue({ fbp: event.data.fbp });
            }
            if (event.data.hubspotutk === 'null' || event.data.hubspotutk === null || event.data.hubspotutk === undefined) {
                form.setFieldsValue({ hubspotutk: " " });
            } else {
                form.setFieldsValue({ hubspotutk: event.data.hubspotutk });
            }
            if (event.data.userAgent) { form.setFieldsValue({ userAgent: event.data.userAgent }); }
            changeHeight(refForm.current.clientHeight);
        }
    });

    const onFinish = async (values) => {

        const formData = {
            "name": values.name,
            "email": values.email,
            "phone": "+" + values.phone.countryCode + values.phone.areaCode + values.phone.phoneNumber,
            "insurance": values.insurance,
            "ciudadResidencia": values.ciudadResidencia,
            "fechaNacimiento": values.fechaNacimiento.format("DD/MM/YYYY"),
            "web_origen": values.url
        };

        setButtonLoading(true);
        try {
            const response = await axios.post('https://deicnwlen74pn7opta6espxfd40dxbgx.lambda-url.eu-west-3.on.aws/', formData, {
                responseType: 'arraybuffer'
            });

            if (response.status === 200) {
                setButtonLoading(false);
                setSuccess(true);
                setResult("block");
            }
        } catch (error) {
            console.log(error);
            setButtonLoading(false);
            openNotification('topRight');
        }
    };

    const openNotification = (placement) => {
        api.info({
            message: 'Error inesperado',
            description: 'Ha ocurrido un error inesperado. Intentelo más tarde. Disculpe las molestias',
            placement,
            icon: (
                <CloseCircleOutlined style={{ color: "#f7151c" }} />
            )
        });
    };

    const disabledDateBefore18YearsAgo = (current) => {
        const eighteenYearsAgo = dayjs().subtract(18, 'years');
        return current && current > eighteenYearsAgo;
    };

    return (
        <>
            {contextHolder}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: primaryColor,
                    },
                }}
            >
                <style>
                    {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                    `}
                </style>
                <div ref={refForm}>
                    <Card
                        title={searchParams.get("title") ? "Fill the form and we will get in touch with you as soon as possible" : ""}
                        // Si quiere mostrar el title tambien el background color sera backgroundColor: "#f0f2f5"
                        style={{ width: "100%", backgroundColor: searchParams.get("title") ? "#f0f2f5" : "white" }}
                        bordered={false}
                        headStyle={{
                            backgroundColor: primaryColor,
                            color: "white"
                        }}
                    >
                        <div>
                            <Form
                                form={form}
                                style={{ padding: searchParams.get("title") ? "1rem" : "" }}
                                hidden={success}
                                name="basic"
                                initialValues={{ remember: true }}
                                layout='vertical'
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    hidden={true}
                                    label="URL"
                                    name="url"
                                >
                                    <Input type='text' />
                                </Form.Item>

                                <Form.Item
                                    hidden={true}
                                    label="FB"
                                    name="fbp">
                                    <Input type='' />
                                </Form.Item>

                                <Form.Item
                                    hidden={true}
                                    label="Hubspotutk"
                                    name="hubspotutk">
                                    <Input type='' />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>What's your full name?</p>}
                                    name="name"
                                    rules={[{ required: true, message: 'Please, input your full name' }]}
                                >
                                    <Input placeholder="Your full name" disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>What's your email?</p>}
                                    name="email"
                                    rules={[{ required: true, message: 'Please, input your email' }]}
                                >
                                    <Input type='email' placeholder="Your email" disabled={buttonLoading} />
                                </Form.Item>

                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>What's your phone number?</p>}
                                    name="phone"
                                    validateTrigger="onSubmit" // Esto asegura que la validación solo se dispare al enviar el formulario
                                    rules={[
                                        { required: true, message: 'Please, input your phone number' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value) {
                                                    const isValid = value.valid();
                                                    if (!isValid) {
                                                        return Promise.reject(new Error('The phone number is not valid'));
                                                    }
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <PhoneInput disableParentheses={true} style={{ width: '100%' }} disabled={buttonLoading} onChange={(value) => console.log(value)} />
                                </Form.Item>

                                {/* Ciudad de residencia en España */}
                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>What's your city of residence in Spain?</p>}
                                    name="ciudadResidencia"
                                    rules={[{ required: true, message: 'Please, input your city of residence in Spain' }]}
                                >
                                    <Input placeholder="Your city of residence in Spain" disabled={buttonLoading} />
                                </Form.Item>
                                
                                {/* Fecha de nacimiento, no se debe permitir que sea menor de edad */}
                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>What's your date of birth?</p>}
                                    name="fechaNacimiento"
                                    rules={[{ required: true, message: 'Por favor, ingrese su fecha de nacimiento' }]}
                                >
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        disabledDate={disabledDateBefore18YearsAgo}
                                        style={{ width: '100%' }}
                                        showToday={false}
                                        disabled={buttonLoading}
                                        defaultPickerValue={dayjs().subtract(18, 'years')}
                                    />
                                </Form.Item>


                                <Form.Item
                                    style={{ marginBottom: "0.6rem" }}
                                    label={<p style={{ fontSize: "15px", marginTop: "-6px", marginBottom: "-6px" }}>What type of insurance are you interested in?</p>}
                                    name="insurance"
                                    rules={[{ required: true, message: 'Please, select an option' }]}
                                >
                                    <Select showSearch placeholder='Select an option' disabled={buttonLoading}>
                                        <Select.Option key="Auto" value="Auto">Auto</Select.Option>
                                        <Select.Option key="Home" value="Home">Home</Select.Option>
                                        <Select.Option key="Health" value="Health">Health</Select.Option>
                                        <Select.Option key="Other" value="Other">Other</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item style={{ marginBottom: "0.6rem" }} valuePropName="checked" name="terms" initialValue={false} rules={[{ required: true, validator: async (_, checked) => { if (!checked) { return Promise.reject(new Error("Please, accept the terms and conditions")) } } }]}>
                                    <Checkbox disabled={buttonLoading} >I accept the <Link target='_blank' to='https://www.coventia.es/politica-de-privacidad-y-aviso-de-cookies'>terms and conditions</Link></Checkbox>
                                </Form.Item>

                                <Button type='primary' loading={buttonLoading} htmlType="submit" style={{ width: "100%", height: "35px" }}>
                                    {buttonLoading ? 'Sending...' : 'Send'}
                                </Button>
                            </Form>

                            <Result
                                icon={<CheckCircleFilled style={{ color: "#004A73" }} />}
                                title="Form sent successfully!"
                                subTitle={
                                    <div>
                                        <p>Thank you for filling the form. We have received your information and we will get in touch with you as soon as possible.</p>
                                    </div>
                                }
                                style={{ display: result, textAlign: "center", marginTop: "1rem" }}
                            />
                        </div>
                    </Card>
                </div>
            </ConfigProvider>
        </>
    );
};

export default FormLeadExpat;
